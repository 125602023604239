body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EEEEEE;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

button:focus{
  outline: 0px;
}

.section-container {
  position: absolute;
  margin: 0 auto 0;
  height:  calc(100% - 48px);
  min-height: calc(100% - 48px);
  width: 100%;
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
}

.section-container.fullscreen{
  max-width: initial;
}

@media (min-width: 0px) {
  html {
    font-size: 9px;
  }
}

@media (min-width: 400px) {
  html {
    font-size: 10px;
  }
}

@media (min-width: 1000px) {
  html {
    font-size: 11px;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 1450px) {
  html {
    font-size: 13px;
  }
}

@media (min-width: 1700px) {
  html {
    font-size: 14px;
  }
}
